












































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DataPicker extends Vue {
@Prop({ type: String }) public dateProp: string;
@Prop({ type: String }) public dataPickerLabel: string;

    public menu = false;

    get date() {
        return this.dateProp;
    }

    set date(newProp) {
        // nothing here
    }

    get childDateProp() {
        return this.dateProp;
    }

    set childDateProp(newVal) {
        this.$emit('dateChanged', newVal);
    }
}
