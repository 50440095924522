






















































































import { Component, Prop } from 'vue-property-decorator';
import DataPicker from '@/components/DataPicker.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IBiomarkerResult, ICreateBiomarkerResult, IUpdateBiomarkerResult } from '@/interfaces/biomarkerResults';
import { IListItem } from '@/interfaces/listItem';
import {
  dispatchGetBiomarkerById,
  dispatchGetBiomarkerResultById,
  dispatchGetUsersForList,
} from '@/store/crud/actions';
import { IUser } from '@/interfaces/users';
import { IBiomarker } from '@/interfaces/biomarkers';

@Component({ components: { DataPicker } })
export default class BiomarkerResultForm extends AppComponent {
  @Prop({ required: false, type: Array }) public availableBiomarkers: IListItem [];
  @Prop({ required: false, type: Boolean, default: false }) public isEdit: boolean;
  @Prop({ required: false, type: String }) public biomarkerResultId: string;

  public valid = false;
  public dateOfService: string = '';
  public collectionSite: string = '';
  public testResut: string = '';
  public measurementUnits: string = '';
  public assignedBiomarker: string = '';
  public assignedPyhsician: string = '';
  public assignedTestResult: any = {};
  public outOfRange: boolean = false;
  public questReferenceRange: string = '';

  public editingBiomarkerResult = {} as IBiomarkerResult;
  public busEventId: number = -1;

  /**
   * Component startup
   */
  public async created() {
    this.busEventId = this.$bus.$on('biomarker-results-form:reset', this.initView);
  }

  public async destroyed() {
    this.$bus.$off('biomarker-results-form:reset', this.busEventId);
  }

  public emitCreation(result) {
    this.$emit('biomarker-results:created', result);
  }

  public emitUpdate(result) {
    this.$emit('biomarker-results:updated', result, this.biomarkerResultId);
  }

  public cancel() {
    this.$emit('biomarker-results:cancel');
  }

  public mounted() {
    this.initView();
  }

  public async initView() {
    if (!this.isEdit) {
      this.setAppLoading(true);
    }
    await this.fetchData();
    this.reset();
    if (!this.isEdit) {
      /**
       * this is a problem with Vuetify, when there is a dialog over a dialog (the appLoading) this closes all dialogs
       */
      this.setAppLoading(false);
    }
  }

  /**
   * fetchs data asyncronously
   */
  public async fetchData() {
    await dispatchGetUsersForList(this.$store);
    if (this.isEdit && this.biomarkerResultId && this.biomarkerResultId.length > 0) {
        const result =  await dispatchGetBiomarkerResultById(this.$store, this.biomarkerResultId);
        if (result) {
            this.editingBiomarkerResult = result;
        } else {
            // FIXME - important log here
            // biomarker result does not exist in database or there is an error with the request
            // we continue as creation
            this.isEdit = false;
        }
    }
  }

  /**
   * it reset the form
   */
  public reset() {
    if (this.isEdit && this.editingBiomarkerResult && this.editingBiomarkerResult.id) {
        this.assignedBiomarker = this.editingBiomarkerResult.biomarker
            ? this.editingBiomarkerResult.biomarker.id as string : '';
        this.assignedPyhsician = this.editingBiomarkerResult.orderingPhysician ?
            (this.editingBiomarkerResult.orderingPhysician as IUser).id as string : '';
        this.testResut = this.editingBiomarkerResult.testResult ? this.editingBiomarkerResult.testResult as string : '';
        this.outOfRange = this.editingBiomarkerResult.testResultOutOfRange ?
            this.editingBiomarkerResult.testResultOutOfRange : false;
        this.questReferenceRange = this.editingBiomarkerResult.questReferenceRange ?
            this.editingBiomarkerResult.questReferenceRange as string : '';
        this.collectionSite = this.editingBiomarkerResult.collectionSite ?
            this.editingBiomarkerResult.collectionSite as string : '';
        this.dateOfService = this.editingBiomarkerResult.dateOfService ?
            this.editingBiomarkerResult.dateOfService as string : '';
        this.measurementUnits = this.editingBiomarkerResult.measurementUnits ?
            this.editingBiomarkerResult.measurementUnits as string : '';
    } else {
        this.dateOfService = '';
        this.collectionSite = '';
        this.testResut = '';
        this.measurementUnits = '';
        this.assignedBiomarker = '';
        this.assignedPyhsician = '';
        this.outOfRange = false;
        this.questReferenceRange = '';
    }
    this.$validator.reset();
  }

  /*
  event handler for date picker
  sets the date of the visit
  */
  public setDate(newDate) {
    this.dateOfService = newDate;
  }

  /**
   * it maps the data from the component into the
   * IBiomarkerResults interface and calls the create biomarker result action
   * resulting in a submit to the server
   */
  public async submit() {
    if (await this.$validator.validateAll()) {
        if (this.isEdit) {
            const protoBiomarkerResultUpdate: IUpdateBiomarkerResult = {};
            if (!this.assignedBiomarker) {
                this.toast('Please select a biomarker', true);
            }

            const expandedBiomarker: IBiomarker = await dispatchGetBiomarkerById(this.$store, this.assignedBiomarker);

            this.setFieldIfChanges(protoBiomarkerResultUpdate, 'dateOfService',
                this.dateOfService, this.editingBiomarkerResult.dateOfService);
            this.setFieldIfChanges(protoBiomarkerResultUpdate, 'collectionSite',
                this.collectionSite, this.editingBiomarkerResult.collectionSite);
            this.setFieldIfChanges(protoBiomarkerResultUpdate, 'measurementUnits',
                this.measurementUnits, this.editingBiomarkerResult.measurementUnits);
            this.setFieldIfChanges(protoBiomarkerResultUpdate, 'biomarkerId',
                this.assignedBiomarker, this.editingBiomarkerResult.biomarker
                ? this.editingBiomarkerResult.biomarker.id : '');

            this.setFieldIfChanges(protoBiomarkerResultUpdate, 'biomarkerName',
                expandedBiomarker.name, this.editingBiomarkerResult.biomarker ?
                this.editingBiomarkerResult.biomarker.name : '');

            this.setFieldIfChanges(protoBiomarkerResultUpdate, 'questBiomarkerId',
                expandedBiomarker.questBiomarkerCode, this.editingBiomarkerResult.biomarker ?
                this.editingBiomarkerResult.biomarker.questBiomarkerCode : '');

            this.setFieldIfChanges(protoBiomarkerResultUpdate, 'orderingPhysicianId',
                this.assignedPyhsician, this.editingBiomarkerResult.orderingPhysician
                ? (this.editingBiomarkerResult.orderingPhysician as IUser).id : '');

            if (this.testResut.length > 0) {
                if (this.editingBiomarkerResult.testResult &&
                this.editingBiomarkerResult.testResult.toString().length > 0) {
                    if (this.editingBiomarkerResult.testResult.toString() !== this.testResut) {
                    protoBiomarkerResultUpdate.testResult = this.testResut;
                    }
                } else if (!this.editingBiomarkerResult.testResult
                    || this.editingBiomarkerResult.testResult.toString().length === 0) {
                    protoBiomarkerResultUpdate.testResult = this.testResut;
                }
            } else {
                this.toast('Test result is required', true);
                return;
            }

            this.setFieldIfChanges(protoBiomarkerResultUpdate, 'testResultOutOfRange',
                this.outOfRange, this.editingBiomarkerResult.testResultOutOfRange);

            this.setFieldIfChanges(protoBiomarkerResultUpdate, 'questReferenceRange',
                this.questReferenceRange, this.editingBiomarkerResult.questReferenceRange);

            const biomarkerResultUpdate: IUpdateBiomarkerResult = protoBiomarkerResultUpdate as IUpdateBiomarkerResult;
            if (Object.keys(biomarkerResultUpdate).length > 0) {
                this.emitUpdate(biomarkerResultUpdate);
            }
        } else {
            const protoCreateBiomarkerResult: IBiomarkerResult = {};
            if (this.hasValue(this.assignedBiomarker)) {
              protoCreateBiomarkerResult.biomarkerId = this.assignedBiomarker;
            }
            if (this.hasValue(this.assignedPyhsician)) {
              protoCreateBiomarkerResult.orderingPhysicianId = this.assignedPyhsician as string;
            }
            if (this.hasValue(this.dateOfService)) {
              protoCreateBiomarkerResult.dateOfService = this.dateOfService;
            }
            if (this.hasValue(this.collectionSite)) {
              protoCreateBiomarkerResult.collectionSite = this.collectionSite;
            }
            if (this.hasValue(this.testResut)) {
              protoCreateBiomarkerResult.testResult = this.testResut;
            } else {
              this.toast('Test result is not valid', true);
              return;
            }
            if (this.hasValue(this.questReferenceRange)) {
              protoCreateBiomarkerResult.questReferenceRange = this.questReferenceRange;
            }
            if (this.hasValue(this.measurementUnits)) {
              protoCreateBiomarkerResult.measurementUnits = this.measurementUnits;
            }

            protoCreateBiomarkerResult.testResultOutOfRange = this.outOfRange;
            const createBiomarkerResult: ICreateBiomarkerResult = protoCreateBiomarkerResult as ICreateBiomarkerResult;
            this.emitCreation(createBiomarkerResult);
        }
    }
  }

}
